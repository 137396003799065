import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from '../layout/Container';
import Host from '../components/Host/Host';
import OtherHost from '../components/Host/OtherHost';

const Inner = styled(Container)`
  ${({ $removeTopPadding }) => ($removeTopPadding ? `padding-top: 0px;` : '')}

  position: relative;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.breakpoint('medium')`
    max-width: 1280px;
  `}
`;

const SectionWrap = styled.div`
  padding-top: 0px;
  background: repeating-linear-gradient(
    ${({ theme }) => theme.colours.backgroundPaper},
    ${({ theme }) => theme.colours.backgroundPaper} 25%,
    ${({ theme }) => theme.colours.white} 25.1%,
    ${({ theme }) => theme.colours.white} 100%
  );

  position: relative;

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
      background: repeating-linear-gradient(
    ${({ theme }) => theme.colours.backgroundPaper},
    ${({ theme }) => theme.colours.backgroundPaper} 29.5%,
    ${({ theme }) => theme.colours.white} 29.6%,
    ${({ theme }) => theme.colours.white} 100%
  );
 
  `}
`;

const Title = styled.h1`
  && {
    color: ${({ theme }) => theme.colours.secondary};
  }
`;

const OtherHostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
      flex-direction: row;
  `}
`;

const YourHostSection = ({
  showHeader,
  removeTopPadding,
  speaker,
  withWebsite,
}) => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Speaker" } }
        sort: { fields: data___HostOrder }
      ) {
        edges {
          node {
            data {
              Name
              Host
              MainHost
              WebsiteUri
              Bio {
                childMarkdownRemark {
                  html
                }
              }
              Avatar {
                localFiles {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  const hosts =
    allAirtable?.edges
      ?.map((edge) => edge?.node.data)
      .filter((host) => host.Host) || [];

  const mainHost =
    speaker ||
    allAirtable?.edges
      ?.map((edge) => edge?.node.data)
      .filter((host) => host.MainHost)[0] ||
    [];

  const firstRowHosts = hosts.slice(0, 2);
  const secondRowHosts = hosts.slice(2);

  return (
    <SectionWrap>
      <Inner $removeTopPadding={removeTopPadding}>
        {showHeader && (
          <Title className="h2">
            Meet <span>your hosts</span>
          </Title>
        )}
        {mainHost && (
          <Host
            key={`host_${mainHost.id}`}
            name={mainHost.Name}
            fluidAvatar={mainHost.Avatar?.localFiles[0]?.childImageSharp.fluid}
            html={mainHost.Bio?.childMarkdownRemark.html}
            withWebsite={withWebsite}
            website={mainHost.WebsiteUri}
          />
        )}
        {!speaker && (
          <>
            <OtherHostsWrapper>
              {firstRowHosts &&
                firstRowHosts.map((host) => (
                  <OtherHost
                    key={`host_${host.Name}`}
                    name={host.Name}
                    html={host.Bio?.childMarkdownRemark.html}
                    website={host.WebsiteUri}
                    fluidAvatar={
                      host.Avatar?.localFiles[0]?.childImageSharp.fluid
                    }
                  />
                ))}
            </OtherHostsWrapper>
            <OtherHostsWrapper>
              {secondRowHosts &&
                secondRowHosts.map((host) => (
                  <OtherHost
                    key={`host_${host.Name}`}
                    name={host.Name}
                    html={host.Bio?.childMarkdownRemark.html}
                    website={host.WebsiteUri}
                    fluidAvatar={
                      host.Avatar?.localFiles[0]?.childImageSharp.fluid
                    }
                  />
                ))}
            </OtherHostsWrapper>
          </>
        )}
      </Inner>
    </SectionWrap>
  );
};

YourHostSection.propTypes = {
  showHeader: PropTypes.bool,
  removeTopPadding: PropTypes.bool,
  speaker: PropTypes.object,
  withWebsite: PropTypes.bool,
};

YourHostSection.defaultProps = {
  showHeader: true,
  removeTopPadding: false,
  speaker: null,
  withWebsite: false,
};

export default YourHostSection;
