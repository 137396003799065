import React, { useEffect, useState, ReactNode } from 'react';
import { isToday, parseISO } from 'date-fns';

import { useUserContext } from '../context/UserContext';

interface PaidContentProps {
  children: ReactNode;
  notPaid?: ReactNode;
  freeDate?: string;
  showNotPaid?: boolean;
  alwaysFree?: boolean;
}

const PaidContent = ({
  children,
  notPaid,
  freeDate,
  showNotPaid,
  alwaysFree,
}: PaidContentProps) => {
  const { hasPaidForProduct, isPaid } = useUserContext();
  const [hasMounted, setHasMounted] = useState(false);
  const [isFreeToday, setIsFreeToday] = useState(false);

  const isFreeDate = () => isToday(parseISO(freeDate));

  // Important! Prevent gatsby from rendering the content on the server
  // Removing this stops free content being availalbe on the correct day
  // Leave it alone!
  useEffect(() => {
    setHasMounted(true);
    if (freeDate) {
      setIsFreeToday(isFreeDate());
    }
  }, [freeDate]);

  if (!hasMounted) {
    return null;
  }

  if (
    !showNotPaid &&
    (alwaysFree || isFreeToday || (isPaid && hasPaidForProduct('Online'))) &&
    children
  ) {
    return children;
  }

  return <>{notPaid}</>;
};

export default PaidContent;
